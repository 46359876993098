<script>
  import IntersectionObserver from 'svelte-intersection-observer';
  export let topicId;
  export let filter;
  export let styleClass;

  console.log('filter', filter);

  import axios from 'axios'; 

  let element;
  let segments = [];
  
  const url = `https://www.fmh.de/api/topics/topic/${topicId}/segments`;

  function fetchData(e) {
    // do not fetch if there is already data
    if (segments.length) return false; 
    axios.get(url)
      .then((res) => {
        if (!res.data || res.data.length === 0) return false;
        res.data.segments.forEach((segment) => {
          segments = [...segments, segment]; 
        });
      }).catch((err) => {
        console.error(err);
      });
  }

</script>


  <IntersectionObserver {element} on:intersect={fetchData}>
    <div bind:this={element} class="topic-segments {styleClass}">
      {#each segments as segment}  
        <section class="topic-segments__section {segment.namespace_not_heritable}">
          <h2 class="topic-segments__title">{@html segment.title}</h2>
          <div class="topic-segments__description">
            {@html segment.formattedDescription}
          </div>
        </section>
      {/each}
    </div>
  </IntersectionObserver>

  
<style type="text/scss">.topic-segments__title, .topic-segments__p {
  font-family: Roboto, arial, helvetica, sans-serif; }

.topic-segments__title, .topic-segments__link {
  color: #1194db; }

.topic-segments__title {
  margin: 0.25em 0;
  line-height: 1.3em;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.025em; }

.topic-segments__link {
  display: block;
  text-decoration: none; }
  .topic-segments__link:hover {
    text-decoration: underline; }
</style>
